import React from "react"
import { BlockFragment } from "../../graphql-types"
// import styled from "styled-components"
import { Content, ButtonLink, ButtonLevel } from "../components/primitives"

type Props = {
  blocks: BlockFragment[] | undefined
}

export const Blocks: React.FC<Props> = ({ blocks }) => {
  if (!blocks) return
  return (
    <Content>
      {blocks.map((block, i) => {
        /**
         * core/paragraph
         */
        if (block.__typename === "Wp_CoreParagraphBlock") {
          return (
            <p
              key={i}
              dangerouslySetInnerHTML={{
                __html:
                  (block.attributes?.__typename ===
                    "Wp_CoreParagraphBlockAttributesV3" &&
                    block.attributes.content) ||
                  "",
              }}
            />
          )
        }

        /**
         * core/button
         */
        if (block.__typename === "Wp_CoreButtonBlock") {
          return (
            <ButtonLink
              key={i}
              level={ButtonLevel.secondary}
              href={block.attributes?.url}
            >
              {block.attributes?.text}
            </ButtonLink>
          )
        }

        /**
         * core/heading
         */
        if (block.__typename === "Wp_CoreHeadingBlock") {
          const content = block.attributes?.content
          const level = block.attributes?.level

          return (
            (level === 1 && (
              <h1 key={i} dangerouslySetInnerHTML={{ __html: content || "" }} />
            )) ||
            (level === 2 && (
              <h2 key={i} dangerouslySetInnerHTML={{ __html: content || "" }} />
            )) ||
            (level === 3 && (
              <h3 key={i} dangerouslySetInnerHTML={{ __html: content || "" }} />
            )) ||
            (level === 4 && (
              <h4 key={i} dangerouslySetInnerHTML={{ __html: content || "" }} />
            ))
          )
        }

        /**
         * core/list
         */
        if (block.__typename === "Wp_CoreListBlock") {
          return (
            <ul
              key={i}
              dangerouslySetInnerHTML={{
                __html: block.attributes?.values || "",
              }}
            />
          )
        }

        /**
         * core/quote
         */
        if (block.__typename === "Wp_CoreQuoteBlock") {
          return (
            <blockquote
              key={i}
              dangerouslySetInnerHTML={{
                __html: block.attributes?.value || "",
              }}
            />
          )
        }

        /**
         * acf/arrangement
         */
        if (block.__typename === "Wp_AcfArrangementBlock") {
          console.log(block)
          return (
            <div
              key={i}
              css={`
                background: ${props => props.theme.colors.sectionBackground};
                padding: 2rem;
                margin-top: 1rem;
                margin-bottom: 1.5rem;

                @media screen and (max-width: 40rem) {
                  padding: 1rem;
                }
              `}
            >
              <h3>{block.acf.title}</h3>
              <p>{block.acf.subtitle}</p>
              <ul
                css={`
                  list-style: none;
                  padding-left: 0;

                  li {
                    position: relative;
                    padding-left: 1rem;
                    &:before {
                      content: "•";
                      position: absolute;
                      left: 0.25rem;
                      top: 0;
                      color: ${props => props.theme.colors.primary};
                    }
                  }
                `}
              >
                {block.acf.items.map((item, i) => (
                  <li key={i}>{item.item}</li>
                ))}
              </ul>
            </div>
          )
        }

        return null
      })}
    </Content>
  )
}

export default Blocks
