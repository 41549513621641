import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import { BoxContainer, Section, Content, Intro, Button } from "../components/primitives"
import { PageAndTitleMarkQuery } from "../../graphql-types"
import Blocks from "../components/blocks"
import SpecialBlocks from "../components/specialBlocks"
import NewsletterSignup from "../components/newsletterSignup"
import SEO from "../components/seo"

import Swiper from "react-id-swiper"
import Img from "gatsby-image"

const params = {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 20,
}

export const Page: React.FC<{ data: PageAndTitleMarkQuery }> = ({ data }) => {
  const [activeSlide, updateActiveSlide] = React.useState<number>(0)
  const [swiper, setSwiper] = React.useState(null)

  const next = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const prev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  React.useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", function() {
        updateActiveSlide(swiper.activeIndex)
      })
    }
  }, [swiper])

  return (
    <>
      <SEO data={data.wp?.page?.seo || undefined} wp={data.wp || undefined} />
      <Header
        fluid={
          data.wp?.page?.featuredImage?.localFile?.childImageSharp?.fluid ||
          undefined
        }
        title={data.wp?.page?.title || ""}
      />
      <SpecialBlocks data={data} />

      {data.wp.page?.blocks?.filter(
        (block) =>
          block?.__typename !== "Wp_AcfIntroBlock" &&
          block?.__typename !== "Wp_AcfMenuPdfBlock" &&
          block?.__typename !== "Wp_AcfMenuSectionBlock"
      ).length > 0 && (
        <Section>
          <BoxContainer>
            <Blocks blocks={data.wp.page?.blocks || undefined} />
          </BoxContainer>
        </Section>
      )}
      {data.wp.page?.acf?.gallery && (
        <Section
          css={`
          background: ${props => props.theme.colors.sectionBackground};
          overflow: hidden;
        `}
        >
          <BoxContainer
            css={`
            text-align: center;
            width: 64rem;

            .swiper-container {
              overflow: visible;
              /* max-height: 60vh; */
            }

            .swiper-slide {
              opacity: 0.3;
              transition: 0.2s ease;

              &.swiper-slide-active {
                opacity: 1;
              }
            }
          `}
          >

            <div
              css={`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 3rem;
              margin-bottom: 1rem;
            `}
            >
              <Button onClick={() => prev()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="47.221"
                  height="15.485"
                  viewBox="0 0 47.221 15.485"
                >
                  <g fill="none" stroke="currentColor" strokeWidth="1.6">
                    <path d="M1.721 7.742h45.5" />
                    <path d="M8.308 14.919L1.131 7.742 8.308.565" />
                  </g>
                </svg>
              </Button>
              <div
                css={`
                font-family: ${props => props.theme.fonts.heading};
                line-height: 1;
                text-transform: uppercase;
                font-size: 1rem;
                display: flex;
                align-items: center;
              `}
              >
              <span
                css={`
                  color: ${props => props.theme.colors.primary};
                  font-size: 1.5rem;
                `}
              >
                {activeSlide + 1}
              </span>
                <span
                  css={`
                  color: ${props => props.theme.colors.border};
                  display: block;
                  margin-left: 0.5rem;
                  margin-right: 0.5rem;
                `}
                >
                /
              </span>
                <span>{data.wp.page?.acf?.gallery?.length}</span>
              </div>
              <Button onClick={() => next()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="47.221"
                  height="15.485"
                  viewBox="0 0 47.221 15.485"
                >
                  <g fill="none" stroke="currentColor" strokeWidth="1.6">
                    <path d="M45.5 7.743H0" />
                    <path d="M38.913.566l7.177 7.177-7.177 7.177" />
                  </g>
                </svg>
              </Button>
            </div>
            <Swiper {...params} getSwiper={setSwiper}>
              {data.wp.page?.acf?.gallery?.map((item, i) => (
                <div key={i}>
                  <Img fluid={item?.localFile?.childImageSharp?.fluid} />
                  <div
                    css={`
                    margin-top: 1rem;
                    font-family: ${props => props.theme.fonts.heading};
                    text-transform: uppercase;
                    font-size: 1.5rem;
                  `}
                    dangerouslySetInnerHTML={{ __html: item?.caption }}
                  />
                </div>
              ))}
            </Swiper>
          </BoxContainer>
        </Section>
      )}
      <NewsletterSignup />
    </>
  )
}

export const query = graphql`
  fragment Block on Wp_Block {
    __typename

    ... on Wp_AcfMenuPdfBlock {
      acf {
        pdfs {
          title
          file {
            sourceUrl
            mediaItemUrl
            localFile {
              publicURL
            }
          }
          image {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(webpQuality: 100, maxWidth: 245) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    ... on Wp_AcfMenuSectionBlock {
      acf {
        title
        items {
          name
          description
          price
        }
      }
    }

    ... on Wp_AcfArrangementBlock {
      acf {
        title
        subtitle
        items {
          item
        }
      }
    }

    ... on Wp_CoreButtonBlock {
      attributes {
        ... on Wp_CoreButtonBlockAttributes {
          text
          linkTarget
          url
        }
      }
    }

    ... on Wp_CoreSeparatorBlock {
      name
    }

    ... on Wp_CoreParagraphBlock {
      attributes {
        __typename
        ... on Wp_CoreParagraphBlockAttributesV3 {
          content
        }
      }
    }

    ... on Wp_CoreHeadingBlock {
      attributes {
        content
        level
      }
    }

    ... on Wp_CoreListBlock {
      attributes {
        values
      }
    }

    ... on Wp_AcfIntroBlock {
      acf {
        title
        content
      }
    }

    ... on Wp_CoreQuoteBlock {
      attributes {
        value
      }
    }
  }

  fragment SEO on Wp_SEO {
    canonical
    metaDesc
    metaRobotsNoindex
    metaRobotsNofollow
    title
    opengraphTitle
    twitterTitle
    twitterDescription
    opengraphDescription
    opengraphImage {
      sourceUrl
      localFile {
        childImageSharp {
          fixed(width: 1200, height: 630) {
            src
          }
        }
      }
    }
    twitterImage {
      sourceUrl
      localFile {
        childImageSharp {
          fixed(width: 1200, height: 630) {
            src
          }
        }
      }
    }
  }

  fragment FeaturedImage on Wp_MediaItem {
    sourceUrl
    localFile {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 600, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 630) {
          src
        }
      }
    }
  }

  fragment Page on Wp_Page {
    title
    uri
    featuredImage {
      ...FeaturedImage
    }
    blocks {
      ...Block
    }
    seo {
      ...SEO
    }
  }

  query PageAndTitleMark($id: ID!) {
    wp {
      page(id: $id) {
        ...Page
        acf {
          gallery {
            caption(format: RENDERED)
            sourceUrl
            localFile {
              childImageSharp {
                fluid(webpQuality: 100, maxWidth: 1024, maxHeight: 640) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      
    }

    file(relativePath: { eq: "beeldmerk-black.png" }) {
      childImageSharp {
        fixed(width: 100, height: 245, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default Page

/*
  ... on Wp_CoreImageBlock {
      attributes {
        mediaItem {
          id
          altText
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    ... on Wp_CoreGalleryBlock {
      attributes {
        mediaItems {
          edges {
            node {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    */
