import React, { useState } from "react"
import { PageAndTitleMarkQuery } from "../../graphql-types"
import { Section, Intro, Content, BoxContainer } from "./primitives"
import Img from "gatsby-image"
import Icons from "./icons"
import FsLightbox from "fslightbox-react"

type Props = {
  data: PageAndTitleMarkQuery
}

export const SpecialBlocks: React.FC<Props> = ({ data }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })
  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    })
  }
  const items =
    data.wp.page?.blocks &&
    data.wp.page?.blocks.map((block, index) => {
      if (block?.__typename === "Wp_AcfIntroBlock") {
        return (
          <Intro key={index}>
            <div>
              {data.file?.childImageSharp && (
                <Img fixed={data.file?.childImageSharp.fixed} />
              )}
              <h2
                dangerouslySetInnerHTML={{
                  __html: block.acf?.title || "",
                }}
              />
            </div>
            <Content
              dangerouslySetInnerHTML={{
                __html: block.acf?.content || "",
              }}
            />
          </Intro>
        )
      }
      if (block?.__typename === "Wp_AcfMenuPdfBlock") {
        let files: [] = []
        block?.acf?.pdfs.map(pdf => {
          const publicUrl: string | undefined | null =
            pdf?.file?.localFile?.publicURL
          if (publicUrl && publicUrl.includes(".pdf")) {
            const pdfIframe: HTMLIFrameElement = (
              <iframe
                height="1080"
                width="1920"
                src={publicUrl}
                allowFullScreen="allowfullscreen"
              />
            )
            files.push(pdfIframe)
          } else if (publicUrl) {
            files.push(publicUrl)
          }
        })
        return (
          <BoxContainer
            key={index}
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
              gap: 2.5rem;
            `}
          >
            {block?.acf?.pdfs.map((pdf: object, index: number) => (
              <a
                href="#"
                onClick={() => openLightboxOnSlide(index + 1)}
                css={`
                  display: grid;
                  border-radius: ${props => props.theme.borderRadius};
                  overflow: hidden;
                  grid-template-columns: 1fr 2fr;
                  background: ${props => props.theme.colors.background};
                  text-decoration: none;
                  color: inherit;
                  box-shadow: 0 14px 30px rgba(0, 0, 0, 0);
                  transition: box-shadow 0.3s ease;

                  & > div > svg {
                    transform: translateX(0);
                    transition: transform 0.3s ease;
                  }

                  &:hover {
                    box-shadow: 0 14px 30px rgba(0, 0, 0, 0.1);

                    & > div > svg {
                      transform: translateX(0.25rem);
                    }
                  }
                `}
              >
                {pdf.image !== null && (
                  <Img
                    fluid={pdf.image.localFile.childImageSharp.fluid}
                    css={`
                      max-height: 10rem;
                      opacity: ${props => props.theme.imageOpacity};
                    `}
                  />
                )}
                <div
                  css={`
                    padding: 1rem;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  `}
                >
                  <div
                    css={`
                      display: flex;
                      align-items: center;
                      margin-right: 1rem;
                    `}
                  >
                    <Icons.Cart style={{ marginRight: "0.5rem" }} />
                    <h3
                      css={`
                        font-size: calc(1.25rem + 0.5vw);
                        font-family: ${props => props.theme.fonts.heading};
                        color: ${props => props.theme.colors.primary};
                      `}
                    >
                      {pdf.title}
                    </h3>
                  </div>
                  <Icons.Arrow
                    style={{
                      position: "absolute",
                      right: "2rem",
                      bottom: "1.5rem",
                    }}
                  />
                </div>
              </a>
            ))}
            <FsLightbox toggler={lightboxController.toggler} sources={files} />
          </BoxContainer>
        )
      }

      if (block?.__typename === "Wp_AcfMenuSectionBlock") {
        return (
          <BoxContainer key={index}>
            <h3
              css={`
                font-family: ${props => props.theme.fonts.heading};
                font-size: 1.5rem;
                margin-bottom: 1rem;
                text-transform: uppercase;
                display: flex;
                align-items: center;
              `}
            >
              <Icons.Cart style={{ marginRight: "0.5rem" }} />
              <span
                css={`
                  color: ${props => props.theme.colors.primary};
                `}
              >
                {block?.acf?.title}
              </span>
            </h3>
            <ul
              css={`
                margin: 0;
                padding: 0;

                & > * + * {
                  margin-top: 0.25rem;
                }
              `}
            >
              {block?.acf?.items.map((item, index) => (
                <li
                  key={index}
                  css={`
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    background: ${props => props.theme.colors.background};
                  `}
                >
                  <div>
                    <h4
                      css={`
                        font-weight: 700;
                        font-size: 1.125rem;
                      `}
                    >
                      {item.name}
                    </h4>
                    <p>{item.description}</p>
                  </div>
                  <span
                    css={`
                      display: block;
                      margin-left: auto;
                      font-weight: 700;
                      font-size: 1.25rem;
                    `}
                  >
                    {item.price}
                  </span>
                </li>
              ))}
            </ul>
          </BoxContainer>
        )
      }

      return null
    })

  return (
    <Section
      css={`
        background: ${props => props.theme.colors.sectionBackground};
      `}
    >
      {items}
    </Section>
  )
}

export default SpecialBlocks
